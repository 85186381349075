import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutImage from '../components/image-wrappers/about/about-image';
import Img from 'gatsby-image';

const aboutQuery = graphql`
    query AboutPageQuery {
        allMarkdownRemark(filter: {frontmatter: {pageName: {eq: "about"}}}) {
            nodes {
                html
                frontmatter {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1360) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;

const About = ({ data }) => (
    <Layout>
        <SEO
            title="About J Clarke Fitness"
            description="Read about my mission and what I am doing to redefine how people view fitness. Workouts and meal plans that complement and enhance your lifestyle."
        />
        <div className="content">
            <div>
                <div className="responsive-image">
                    <Img fluid={data.frontmatter.image.childImageSharp.fluid} alt="Jonathan Clarke" />
                </div>
                <h1>About me</h1>
                <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
        </div>
        <style jsx>{`
            .content {
                background-color: hsl(4, 16%, 96%);

                & > div {
                    max-width: 692px;
                    width: 100%;
                    padding: 32px 24px 40px;
                    box-sizing: border-box;
                    margin: 0 auto;
                }
            }

            @media (min-width: 800px) {
                .content {
                    & > div {
                        padding: 32px 32px 40px;
                    }
                }
            }

            .responsive-image {
                overflow: hidden;
                border-radius: 3px;
                margin-top: 24px;

                & > img {
                    display: block;
                    width: 100%;
                }
            }
        `}</style>
    </Layout>
);


export default () => (
    <StaticQuery query={aboutQuery} render={(data) => {
        const { nodes } = data.allMarkdownRemark;
        const pageData = nodes[0];
        return <About data={pageData} />
    }} />
)
